

import { defineComponent, reactive, toRefs, onMounted, unref, ref } from 'vue';
import { getArticles } from '@/apis/articles';
import { ArticleModel } from '@/model/articleModel';
import { formatJson } from '@/utils';
import { exportJson2Excel } from '@/utils/excel';
import { ElTable, ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const dataMap = reactive({
      list: Array<ArticleModel>(),
      listLoading: true,
      downloadLoading: false,
      multipleSelection: [],
      filename: ''
    });
    const multipleTableNode = ref(ElTable);
    const fetchData = async() => {
      dataMap.listLoading = true;
      const data = await getArticles({ /* Your params here */ });
      dataMap.list = data?.data.items ?? [];
      // Just to simulate the time of the request
      setTimeout(() => {
        dataMap.listLoading = false;
      }, 0.5 * 1000);
    };

    const handleSelectionChange = (value: any) => {
      dataMap.multipleSelection = value;
    };

    const handleDownload = () => {
      const from = unref(multipleTableNode);
      if (dataMap.multipleSelection.length) {
        dataMap.downloadLoading = true;
        const tHeader = ['Id', 'Title', 'Author', 'Readings', 'Date'];
        const filterVal = ['id', 'title', 'author', 'pageviews', 'timestamp'];
        const list = dataMap.multipleSelection;
        const data = formatJson(filterVal, list);
        exportJson2Excel(tHeader, data, dataMap.filename !== '' ? dataMap.filename : undefined);
        from.clearSelection();
        dataMap.downloadLoading = false;
      } else {
        ElMessage.warning('Please select at least one item');
      }
    };

    onMounted(() => {
      fetchData();
    });
    return { t, ...toRefs(dataMap), fetchData, handleDownload, handleSelectionChange };
  }
});
